<template>
  <div class="box">
    <div class="ttop">
      <div class="topbox"></div>
      <div class="mianbox">
        <div class="leftbox">
          <div class="logo">
            <img src="~assets/logo2.svg" alt="" />
          </div>

          <dl v-for="(item, index) in msg" :key="index">
            <dt>{{ item.title }}</dt>
            <dd v-for="(option, i) in item.cont" :key="i" @click="topage(i)">
              {{ option }}
            </dd>
          </dl>
          <ul>
            <li v-for="(item, index) in msg2" :key="index">{{ item }}</li>
          </ul>
        </div>
        <div class="midbox">
          <dl v-for="(item, index) in msg3" :key="index">
            <dt>{{ item.title }}</dt>
            <dd
              v-for="(option, i) in item.cont"
              class="a show"
              :key="i"
              @click="toweb(i)"
            >
              {{ option }}
            </dd>
          </dl>
          <van-row class="jieshao">
            <van-row type="flex" justify="start" gutter="10">
              <van-col
                style="margin-bottom: 0.8vw"
                v-for="(item, index) in cooperation"
                :key="index"
                :span="8"
              >
                <div style="width: 6.125vw">
                  <img :src="item.img" alt="" />
                </div>
              </van-col>
            </van-row>
          </van-row>
        </div>
        <div class="rightbox">
          <ul>
            <li class="asd" v-for="(item, index) in msg5" :key="index">
              {{ item }}
            </li>
          </ul>
          <van-row type="flex" justify="space-around" class="downZONG">
            <van-col>
              <div class="downimgsize">
                <img src="~assets/down1.png" alt="" />
              </div>
              <div class="downtitle">用户端</div>
            </van-col>
            <van-col>
              <div class="downimgsize">
                <img src="~assets/down2.png" alt="" />
              </div>
              <div class="downtitle">医生端</div>
            </van-col>
            <van-col>
              <div class="downimgsize">
                <img src="~assets/down3.png" alt="" />
              </div>
              <div class="downtitle">公众号</div>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
    <div class="bottoms">
      <div>
        <span>Copyright©2016-2021 未经授权请勿转载</span>
        <span class="show a" @click="tobei()">浙ICP备19052182号-1</span>
        <!-- <span>浙公网安备5001900251234号</span> -->
      </div>
      <div class="bt">
        <span>特别声明：本站内容仅供参考，不作为诊断及医疗依据</span>
        <span
          >免责声明：本站部分文字及图片来自于网络，如侵犯到您的权益，请及时通知我们进行删除处理</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottom",
  components: {},
  data() {
    return {
      cooperation: [
        {
          img: require("../../../assets/cooperation/coo3.png"),
        },
        {
          img: require("../../../assets/cooperation/coo5.png"),
        },
        {
          img: require("../../../assets/cooperation/coo4.png"),
        },
        {
          img: require("../../../assets/cooperation/coo2.png"),
        },
        {
          img: require("../../../assets/cooperation/coo1.png"),
        },
        // {
        //   img: require("../../../assets/cooperation/coo5.png"),
        // },
        // {
        //   img: require("../../../assets/cooperation/coo6.png"),
        // },
        // {
        //   img: require("../../../assets/cooperation/coo7.png"),
        // },
        // {
        //   img: require("../../../assets/cooperation/coo8.png"),
        // },
        // {
        //   img: require("../../../assets/cooperation/coo9.png"),
        // },
      ],
      msg: [
        {
          title: "瑞尔安心旗下网站",
          cont: ["瑞尔安心——健康科普平台"],
          // cont: ["瑞尔安心——健康科普平台", "瑞尔安心——在线医美平台"],
        },
      ],
      msg2: [
        "TEL：010-86398553",
        "E-mail：commercial@irealcare.com",
        "工作日（周一至周五）：9:00--18:00",
      ],
      msg3: [
        {
          title: "友情链接",
          cont: [
            "中华人民共和国国家卫生健康委员会",
            "中华医学会",
            "瑞尔安心——健康科普平台",
          ],
        },
      ],
      msg5: [
        "瑞尔安心用户端APP",
        "瑞尔安心医生端APP",
        "瑞尔安心官方微信公众号",
      ],
    };
  },
  methods: {
    topage(e) {
      if (e === 0) {
        window.open("http://www.irealcare.com/");
      }
    },
    toweb(e) {
      if (e === 0) {
        window.open("http://www.nhc.gov.cn/");
      }
      if (e === 1) {
        window.open("https://www.cma.org.cn/");
      }
      if (e === 2) {
        window.open("http://www.irealcare.com/");
      }
    },
    // to(e) {
    //   if (e === 0) {
    //     window.open("http://www.irealcare.com/");
    //   }
    // },
    tobei() {
      window.open(
        "https://beian.miit.gov.cn/?spm=5176.17724490.J_9220772140.115.10545c73DA0EP0#/Integrated/index"
      );
    },
  },
};
</script>

<style scoped>
.box {
  height: 26.615vw;
  margin-left: 17.708vw;
  margin-right: 17.708vw;
  /* text-align: center; */
}
.ttop {
  height: 21.094vw;
  margin-bottom: 2.604vw;
}
.topbox {
  width: 64.583vw;
  height: 0.052vw;
  background-color: #e4e4e4;
}
.mianbox {
  display: flex;
}
.midbox {
  width: 22.833vw;
}
.rightbox {
  width: 22.833vw;
}
.leftbox {
  margin-left: 1.042vw;
  width: 16.833vw;
}
.er {
  margin-top: 1.042vw;
}
.er img {
  margin-right: 1.042vw;
}
img {
  width: 100%;
  height: 100%;
}
.mind {
  width: 3.125vw;
  height: 3.281vw;
  background-color: #e4e4e4;
}
.van-col--5 {
  width: 3.125vw;
}
.leftbox .logo {
  width: 13.75vw;
  /* height: 2.5vw; */
  margin-top: 2.969vw;
}
.logo img {
  width: 100%;
  height: 100%;
}
dl {
  margin-top: 2.135vw;
  font-size: 0.625vw;
}
dl dt {
  color: #252222;
  margin-top: 0.625vw;
  font-size: 0.625vw;
}
dl dd {
  color: #a4a0a0;
  margin-top: 0.417vw;
  margin-inline-start: 0vw;
  font-family: "OPPOSansR";
}
dd {
  cursor: pointer;
}
ul {
  font-size: 0.729vw;
  color: #524f4f;
  margin-top: 3.542vw;
}
ul li {
  margin-top: 0.417vw;
}
.bottoms {
  height: 5.521vw;
  margin-top: 1.51vw;
  font-family: "OPPOSansR";
}
.van-col--offset-1 {
  margin-left: 0.521vw;
  margin-top: 1.042vw;
}
.jieshao {
  margin-top: 3.646vw;
  /* width: 19.271vw; */
  margin-right: 2.771vw;
}
.rightbox {
  margin-left: 0.721vw;
}
.rightbox ul {
  margin-top: 2.5vw;
  border-bottom: 0.052vw solid #a4a0a0;
}
.rightbox .asd {
  border-top: 0.052vw solid #a4a0a0;
  color: #a4a0a0;
  padding: 1.042vw 0vw;
  font-family: "OPPOSansR";
}
.a {
  cursor: pointer;
}
.bottoms {
  padding-top: 1.51vw;
  text-align: center;
  font-size: 0.573vw;
  color: #a4a0a0;
}
.bottoms span {
  margin-right: 0.885vw;
}
.bt {
  margin-top: 1.188vw;
}
.downZONG {
  margin-top: 2.083vw;
}
.downZONG .downtitle {
  text-align: center;
  color: #524f4f;
  font-size: 0.625vw;
  font-family: "OPPOSansR";
}
.show:hover {
  color: #252222;
}
.van-col--4 {
  width: 18%;
}
.downimgsize {
  width: 4vw;
  height: 4vw;
}
.downimgsize img {
  width: 100%;
}
</style>
