<template>
  <div id="app">
    <div v-if="showPage === 2">
      <div class="tishi" v-if="tishi" ref="element">
        <div class="title">
          <span
            >中国心血管病患病人数已达3.3亿，每年有超过四百万人因心血管疾病死亡，占死亡人数的40%以上，心血管疾病已经成为为我国居民的首要死因。————</span
          >
          <a @click="toreport()">《中国心血管健康与疾病报告（2019）》</a>
        </div>
      </div>
      <nav-bar
        v-if="isShow"
        :class="searchBarFixed == true ? 'isFixed' : ''"
      ></nav-bar>
      <div class="zhegaibox" v-if="isShow"></div>
      <router-view name="pc"></router-view>
      <bottom-bar class="bottom"></bottom-bar>
    </div>
    <div v-if="showPage === 1">
      <div class="zhegai" v-if="zhegai"></div>
      <div class="mobiletishi" v-show="tishi && nosh" ref="element">
        <div class="title">
          <span
            >中国心血管病患病人数已达3.3亿，每年有超过四百万人因心血管疾病死亡，占死亡人数的40%以上，心血管疾病已经成为为我国居民的首要死因。</span
          >
          <div class="chuchu">
            ————<a @click="toreport()">《中国心血管健康与疾病报告（2019）》</a>
          </div>
        </div>
      </div>
      <z-nav-bar
        v-show="mobileisShow"
        @noshow="noshow"
        @showzhegai="showzhegai"
        @matop="matop"
        :sfix="sfix"
        class="z_nav_bar"
        :class="searchBarFixed == true ? 'isFixed' : ''"
        ref="pronbit"
      ></z-nav-bar>
      <div class="zhegaiboxz" v-if="mobileisShow"></div>
      <router-view name="mobile"></router-view>
      <z-bottom-bar class="bottom" v-show="bottomshow"></z-bottom-bar>
    </div>
  </div>
</template>
<script>
import NavBar from "components/content/navbar/NavBar";
import ZNavBar from "components/content/navbar/mobile/NavBar";
import BottomBar from "components/content/Bottom/BottomBar";
import ZBottomBar from "components/content/Bottom/mobile/BottomBar";
export default {
  name: "App",
  components: {
    NavBar,
    ZNavBar,
    BottomBar,
    ZBottomBar,
  },
  data() {
    return {
      height: document.documentElement.clientHeight,
      mobileheight: 0,
      isShow: true,
      tishi: true,
      moveto: false,
      showPage: 1,
      nosh: true,
      sfix: true,
      mobileisShow: false,
      bottomshow: true,
      zhegai: false,
      searchBarFixed: false,
      top: 0,
    };
  },
  methods: {
    toreport() {
      window.open(
        "https://www.nccd.org.cn/Sites/Uploaded/File/2021/5/%E4%B8%AD%E5%9B%BD%E5%BF%83%E8%A1%80%E7%AE%A1%E5%81%A5%E5%BA%B7%E4%B8%8E%E7%96%BE%E7%97%85%E6%8A%A5%E5%91%8A%EF%BC%882019%EF%BC%89%E5%90%88.pdf"
      );
    },
    noshow(val) {
      // console.log(val)
      if (val === "noshow") {
        this.nosh = false;
      } else {
        this.nosh = true;
      }
    },
    matop(val) {
      this.top = val;
    },
    showzhegai(val) {
      console.log(val);
      if (val === "showzhegai") {
        this.zhegai = true;
      }
      if (val === "wushowzhegai") {
        this.zhegai = false;
      }
    },
    handleScroll() {
      // 页面滑动的距离
      let scrollTop = document.documentElement.scrollTop;
      //监听到滚动后，遮盖消失
      if (scrollTop > 10 + this.top) {
        this.zhegai = false;
      }
      // 当页面滑动的距离大于300px时元素显示，否则不显示
      // if (this.showPage === 1) {
      //   if (
      //     this.$route.fullPath === "/home" ||
      //     this.$route.fullPath === "/aboutus"
      //   ) {
      //     this.moveto = false;
      //     this.mobileisShow = false;
      //     if (scrollTop >= this.height) {
      //       this.mobileisShow = true;
      //     } else {
      //       this.mobileisShow = false;
      //     }
      //   } else {
      //     this.mobileisShow = true;
      //   }
      // }
      if (this.showPage === 2) {
        if (this.$route.fullPath === "/") {
          this.moveto = false;
          if (scrollTop >= this.height) {
            this.isShow = true;
          } else {
            this.isShow = false;
          }
        } else {
          this.isShow = true;
        }
      }
      if (this.$route.fullPath === "/topatient") {
        this.isShow = true;
        this.searchBarFixed = true;
        if (scrollTop > sessionStorage.getItem("height")) {
          this.searchBarFixed = true;
        } else {
          this.searchBarFixed = false;
        }
      }
    },
  },
  watch: {
    $route: function (newVal) {
      if (newVal === "/" || this.$route.fullPath === "/") {
        this.isShow = false;
        this.tishi = false;
        this.mobileisShow = false;
        this.bottomshow = true;
      }
    },
    "$route.path": function (newVal, oldVal) {
      if (this.$route.path === "/topatient") {
        this.sfix = false;
      } else {
        this.sfix = true;
      }
      console.log(this.$route.path);
      if (newVal === "/" || this.$route.fullPath === "/") {
        this.isShow = false;
        this.tishi = false;
        this.mobileisShow = false;
        this.bottomshow = true;
      } else if (newVal === "/topatient") {
        this.tishi = true;
        this.isShow = true;
        this.mobileisShow = true;
        this.bottomshow = true;
      } else if (newVal === "/aboutus") {
        this.tishi = false;
        this.mobileisShow = false;
        this.bottomshow = true;
      } else if (newVal === "/toperson") {
        this.isShow = true;
        this.tishi = false;
        this.mobileisShow = true;
        this.bottomshow = false;
        // console.log(newVal);
        console.log(oldVal);
      }
    },
    // deep: true,
    // immediate: true,
  },
  mounted() {
    sessionStorage.setItem("height", this.$refs.element.offsetHeight);
    window.addEventListener("scroll", this.handleScroll);
    // this.$bus.on("bannerheight", (value) => {
    //   this.mobileheight = value;
    //   // console.log(value);
    // });
    if (this.showPage === 1) {
      if (this.$route.fullPath === "/" || this.$route.fullPath === "/aboutus") {
        this.moveto = false;
        this.mobileisShow = false;
      } else {
        this.mobileisShow = true;
      }
    }
  },
  created() {
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      this.showPage = 1;
    } else {
      this.showPage = 2;
    }
  },
};
</script>
<style>
.zhegaibox {
  height: 3.385vw;
}
.zhegaiboxz {
  height: 5.25rem;
}
@font-face {
  font-family: "OPPOSansM";
  src: url("~assets/fonts/OPPOSans-M.ttf");
}
@font-face {
  font-family: "OPPOSansR";
  src: url("~assets/fonts/OPPOSans-R.ttf");
}
#app {
  font-family: "OPPOSansM";
  margin: 0 auto;
  /* width: 100vw; */
  /* height: 100%; */
}
body,
html {
  margin: 0;
  padding: 0;
  /* width: 100vw; */
  /* height: 100%; */
  border: none;
}
.bottom {
  margin-top: 3.281vw;
}
</style>

<style scoped>
a {
  cursor: pointer;
  text-decoration: none;
}
.tishi {
  /* width: 100vw; */
  height: 2.917vw;
  background-color: #ebecf8;
}
.tishi span {
  color: #252222;
  font-size: 0.833vw;
}
.tishi a {
  color: #383ebd;
  font-size: 0.833vw;
}
.tishi .title {
  display: flex;
  height: 2.917vw;
  align-items: center;
  justify-content: center;
}
.mobiletishi {
  /* height: 5.25rem; */
  padding: 0.5rem 0rem;
  background-color: #ebecf8;
}
.mobiletishi .title {
  margin: 0 auto;
  color: #252222;
  font-size: 0.75rem;
  line-height: 1rem;
  width: 21.25rem;
}
.chuchu {
  text-align: right;
}
.chuchu a {
  color: #383ebd;
}
.zhegai {
  position: absolute;
  top: 0;
  height: 10000%;
  bottom: 0;
  left: 0;
  right: 0;
  background: #252222;
  opacity: 0.5;
  z-index: 1;
}
.isFixed {
  position: fixed;
  background-color: #fff;
  top: 0;
  z-index: 999;
  width: 100%;
}
.z_nav_bar {
  z-index: 99;
}
</style>
