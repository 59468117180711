import Vue from "vue";
import VueRouter from "vue-router";
import getPageTitle from "@/assets/js/getPageTitle";
// const Home = () => import("../views/home/Home");
// const AboutUs = () => import("../views/aboutus/AboutUs");
// const ToPatient = () => import("../views/topatient/ToPatient");
// const ToPerson = () => import("../views/toperson/ToPerson");
var isPhone = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);
// 1.安装插件
Vue.use(VueRouter);
// let pic = function (vals) {
//   let obj = null
//   let val = vals
//   if(isPhone){
//     obj = {
//       mobilechild:resolve=> require([('../views/mobile/'+val)],resolve)
//     }
//   }else{
//     obj = {
//       pcchild:resolve=> require([('../views/pc/'+val)],resolve)
//     }
//   }
//   return obj
// }
let children_not = function (vals) {
  let obj = null;
  let val = vals;
  if (isPhone) {
    obj = {
      mobile: (resolve) => require(["../views/mobile/" + val], resolve),
    };
  } else {
    obj = {
      pc: (resolve) => require(["../views/pc/" + val], resolve),
    };
  }
  return obj;
};
// 2.创建router
const routes = [
  {
    path: "",
    // redirect: "/home",
    components: children_not("home/Home"),
    meta: {
      title: "主页",
    },
  },
  {
    path: "/",
    components: children_not("home/Home"),
    meta: {
      title: "主页",
    },
  },
  {
    path: "/aboutus",
    components: children_not("aboutus/AboutUs"),
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/topatient",
    components: children_not("topatient/ToPatient"),
    meta: {
      title: "面向患者",
    },
  },
  {
    path: "/toperson",
    components: children_not("toperson/ToPerson"),
    meta: {
      title: "面向专业人员",
    },
  },
];

//解决路由重复点击报错问题
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  if (typeof location == "string") {
    var Separator = "&";
    if (location.indexOf("?") == -1) {
      Separator = "?";
    }
    location = location + Separator + "random=" + Math.random();
  }
  return routerPush.call(this, location).catch((error) => error);
};

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, saveTop) {
    document.title = getPageTitle(to.meta.title);
    if (saveTop) {
      return saveTop;
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: "hash",
});
export default router;
