import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    CurrentIndex: 3,
  },
  mutations: {
    saveIndex(state, now) {
      state.CurrentIndex = now;
    },
  },
});
