import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vant from "vant";
import VueLazyload from "vue-lazyload";
import { Button, Form, Input, FormItem } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// import vConsole from "@/assets/vconsole.js";
import VueBus from "vue-bus";
import store from "./store/index";
Vue.use(VueBus);
// Vue.use(vConsole);
Vue.use(Button);
Vue.use(Form);
Vue.use(Input);
Vue.use(FormItem);
// Vue.use(Message);
import "vant/lib/index.css";
// import "./assets/css/vantimg.css";
Vue.use(VueLazyload);
Vue.use(Vant);
Vue.config.productionTip = false;

new Vue({
  render: function (h) {
    return h(App);
  },
  router,
  store,
}).$mount("#app");
