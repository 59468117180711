<template>
  <div @click.stop>
    <div
      class="bannerbox"
      ref="element"
      :class="sfix == true || !show ? 'bannerisFixed' : ''"
    >
      <div class="logo" @click="tohome">
        <img src="~assets/logo2.svg" alt="" />
      </div>
      <div class="mid"></div>
      <div class="right">
        <div class="img" @click="toshow">
          <img src="~assets/mobile/menu-on.svg" alt="" v-if="show" />
          <img src="~assets/menu-off.svg" alt="" v-else />
        </div>
      </div>
    </div>
    <collapse-transition>
      <div class="isshow" v-if="!show">
        <ul class="isshowmid">
          <li v-for="(item, index) in bar" :key="index" @click="topage(index)">
            <a>{{ item }}</a>
          </li>
        </ul>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import collapseTransition from "assets/js/collapse";
export default {
  name: "HomeNavigation",
  components: {
    collapseTransition,
  },
  props: {
    sfix: {
      typeof: String,
    },
  },
  data() {
    return {
      bar: ["首页", "面向患者", "面向专业人员", "关于我们"],
      show: true,
      asd: false,
      matop: 0,
      img: require("../../../../assets/menu-on.svg"),
    };
  },
  methods: {
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop;
      if (scrollTop > 10 + this.matop) {
        this.show = true;
      }
    },
    toshow() {
      this.show = !this.show;
      let scrollTop = document.documentElement.scrollTop;
      this.matop = scrollTop;
      this.$emit("matop", this.matop);
      console.log(scrollTop);
    },
    topage(index) {
      this.$emit("showzhegai", "wushowzhegai");
      if (index === 3) {
        this.$router.push({ path: "/aboutus" });
      }
      if (index === 2) {
        this.$router.push({ path: "/toperson" });
      }
      if (index === 1) {
        this.$router.push({ path: "/topatient" });
      }
      if (index === 0) {
        this.$router.push({ path: "/" });
      }
      this.show = true;
      console.log(1);
    },
    tohome() {
      this.$emit("showzhegai", "wushowzhegai");
      this.$router.push({ path: "/" });
    },
    getPath() {
      console.log(this.$route.path);
    },
  },
  watch: {
    show: function (newVal, oldVal) {
      if (!newVal) {
        console.log(oldVal);
        //提示
        this.$emit("noshow", "noshow");
        //遮盖曾
        this.$emit("showzhegai", "showzhegai");
      } else {
        this.$emit("noshow", "no");
        this.$emit("showzhegai", "wushowzhegai");
      }
    },
  },
  created() {
    document.addEventListener("click", () => {
      this.show = true;
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    console.log(this.$route.path);
    if (this.$route.path === "/topatient") {
      this.sfix = false;
    } else {
      this.sfix = true;
    }
  },
};
</script>

<style scoped>
.bannerisFixed {
  position: fixed !important;
}
.bannerbox {
  position: absolute;
  /* height: 3.25rem; */
  /* position: fixed; */
  display: flex;
  align-items: center;
  z-index: 999;
  width: 100%;
  background-color: #ffffff;
}
.bannerbox .logo img {
  margin-left: -2.6rem;
  /* width: 7.0625rem; */
  margin-top: 0.8125rem;
  height: 1.4375rem;
}
.mid {
  flex: 1;
}
/* .mid {
  width: 1.875rem;
} */
.right .img {
  float: right;
  margin-top: 0.7rem;
  margin-right: 0.875rem;
}
.isshow {
  position: absolute;
  z-index: 11;
  position: fixed;
  left: 50%;
  width: 101%;
  top: 52px;
  transform: translate(-50%);
}
.isshowmid {
  /* position: relative; */
  text-align: center;
  margin: 0 auto;
  color: #524f4f;
  width: 101%;
  font-size: 0;
  background-color: #ffffff;
}
.isshowmid li {
  /* position: absolute; */
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  /* bottom: 0px */
  border-top: 1px solid #e4e4e4;
}
.logo {
  height: 51.994px;
}
img {
  width: 100%;
  height: 100%;
}
.collapse-wrap {
  width: 200px;
  background: pink;
  color: #fff;
  margin-left: 100px;
}
.collapse_btn {
  width: 100px;
  height: 50px;
  border: 1px solid;
  border-radius: 25px;
  margin-left: 150px;
  text-align: center;
  line-height: 50px;
  font-size: 22px;
}
.content1 {
  height: 120px;
  font-size: 20px;
}
</style>
