<template>
  <div class="box">
    <dl v-for="(item, index) in msg" :key="index">
      <dt>{{ item.title }}</dt>
      <dd v-for="(option, i) in item.cont" :key="i" @click="to(i)">
        {{ option }}
      </dd>
    </dl>
    <ul>
      <li v-for="(item, index) in msg2" :key="index">{{ item }}</li>
    </ul>
    <div class="midbox">
      <dl v-for="(item, index) in msg3" :key="index">
        <dt>{{ item.title }}</dt>
        <dd
          v-for="(option, i) in item.cont"
          :key="i"
          @click="toweb(i)"
          style="margin-bottom: 0.75rem"
        >
          {{ option }}
        </dd>
      </dl>
    </div>
    <div class="rightbox">
      <ul>
        <li
          class="asd"
          v-for="(item, index) in msg5"
          :key="index"
          @click="todown(index)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottom",
  data() {
    return {
      msg: [
        {
          title: "瑞尔安心旗下网站",
          cont: ["瑞尔安心——健康科普平台"],
          // cont: ["瑞尔安心——健康科普平台", "瑞尔安心——在线医美平台"],
        },
      ],
      msg2: [
        "TEL：010-86398553",
        "E-mail：commercial@irealcare.com",
        "工作日（周一至周五）：9:00--18:00",
      ],
      msg3: [
        {
          title: "友情链接",
          cont: [
            "中华人民共和国国家卫生健康委员会",
            "中华医学会",
            "瑞尔安心——健康科普平台",
          ],
        },
      ],
      msg5: [
        "瑞尔安心用户端APP",
        "瑞尔安心医生端APP",
        "瑞尔安心官方微信公众号",
      ],
    };
  },
  methods: {
    toweb(e) {
      if (e === 0) {
        window.open("http://www.nhc.gov.cn/");
      }
      if (e === 1) {
        window.open("https://www.cma.org.cn/");
      }
      if (e === 2) {
        window.open("http://www.irealcare.com/");
        // window.open("https://beian.miit.gov.cn/");
      }
    },
    todown(e) {
      if (e === 0) {
        window.open("http://ht.irealcare.com/down/");
      }
      if (e === 1) {
        window.open("http://api.irealcare.com/weixin/product/down/doctorApp");
      }
      if (e === 2) {
        window.open(
          "https://weixin.qq.com/r/oERXT6bEXfsireIs9xGy?_tbScancodeApproach_=scan"
        );
      }
    },
    to(e) {
      if (e === 0) {
        // window.open("http://api.irealcare.com/weixin/product/down/userApp");
        window.open("http://www.irealcare.com/");
      }
    },
    tobei() {},
  },
};
</script>

<style scoped>
.box {
  text-align: center;
}
dl {
  font-size: 0.75rem;
  line-height: 0.75rem;
}
dd {
  margin-inline-start: 0rem;
  margin-top: 0.5rem;
  color: #383ebd;
  font-family: "OPPOSansR";
}
dt {
  margin-bottom: 0.75rem;
  color: #252222;
}
ul {
  margin-top: 2.25rem;
}
ul li {
  margin-top: 0.625rem;
  color: #524f4f;
  font-size: 0.75rem;
}
.midbox {
  margin-top: 2.25rem;
}
.rightbox ul {
  margin: 2.5625rem 0rem;
}
.rightbox ul li {
  margin-top: 1.3125rem;
  color: #383ebd;
  font-size: 0.875rem;
  padding-bottom: 1rem;
  border-bottom: 0.0625rem solid #e4e4e4;
  margin: 0rem 2.5rem;
  margin-top: 1em;
}
</style>
