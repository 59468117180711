<template>
  <div>
    <div
      class="bannerbox"
      ref="element"
      :class="sfix == true ? 'bannerisFixed' : ''"
    >
      <div class="logo" @click="tohome">
        <img src="~assets/logo2.svg" alt="" />
      </div>
      <div class="mid">
        <ul>
          <li
            v-for="(item, index) in bar"
            :key="index"
            @click="topage(index)"
            :style="{ color: currentIndex === index ? '#383EBD' : '' }"
          >
            <a>{{ item }}</a>
          </li>
        </ul>
      </div>
      <div class="right">
        <a class="login" href="http://ecgpc.irealcare.com/">医疗机构登录</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeNavigation",
  data() {
    return {
      bar: ["关于我们", "面向专业人员", "面向患者", "首页"],
      sfix: true,
      currentIndex: this.$store.state.CurrentIndex,
    };
  },
  watch: {
    "$route.path": function (newVal) {
      if (newVal === "/") {
        this.currentIndex = 3;
        // this.$store.commit("saveIndex", 3)
      } else if (newVal === "/topatient") {
        this.currentIndex = 2;
        // this.$store.commit("saveIndex", 2)
      } else if (newVal === "/toperson") {
        this.currentIndex = 1;
        // this.$store.commit("saveIndex", 1)
      } else if (newVal === "/aboutus") {
        this.currentIndex = 0;
        // this.$store.commit("saveIndex", 0)
      }
    },
    deep: true,
    immediate: true,
  },
  methods: {
    topage(index) {
      if (index === 0) {
        this.$router.push({ path: "/aboutus" });
        this.$store.commit("saveIndex", 0);
      }
      if (index === 1) {
        this.$router.push({ path: "/toperson" });
        this.$store.commit("saveIndex", 1);
      }
      if (index === 2) {
        this.$router.push({ path: "/topatient" });
        this.$store.commit("saveIndex", 2);
      }
      if (index === 3) {
        this.$router.push({ path: "/" });
        this.$store.commit("saveIndex", 3);
      }
    },
    tohome() {
      this.$router.push({ path: "/" });
      this.$store.commit("saveIndex", 3);
    },
  },
  mounted() {
    if (this.$route.path === "/topatient") {
      this.sfix = false;
    } else {
      this.sfix = true;
    }
  },
};
</script>

<style scoped>
.bannerisFixed {
  position: fixed;
}
a {
  cursor: pointer;
}
.bannerbox {
  width: 100vw;
  /* height: 3.385vw; */
  /* position: fixed; */
  z-index: 11;
  display: flex;
  background-color: #ffffff;
}
.bannerbox .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bannerbox img {
  margin-left: 3.125vw;
  margin-top: 0.729vw;
  margin-bottom: 0.729vw;
  /* width: 6.927vw; */
  height: 1.979vw;
}
ul li {
  float: right;
  margin-left: 3.438vw;
  color: #524f4f;
  font-size: 0.625vw;
}
ul {
  margin-top: 1.146vw;
}

.right {
  /* width: 15.625vw; */
  /* margin-top: 0.833vw; */
  /* margin-bottom: 0.833vw; */
  margin-right: 3.646vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mid {
  flex: 1;
}
.login {
  font-size: 0.625vw;
  color: #524f4f;
  padding: 0.313vw 0.521vw;
  border-radius: 1.042vw;
  border: 0.052vw solid #524f4f;
  margin-left: 4.688vw;
}
</style>
